<template>
    <v-dialog v-model="showDialog" max-width="400px">
        <v-card >
            <v-card-title>Configurar zona horaria <v-icon>mdi-earth</v-icon> </v-card-title>
            <v-card-text>
                <v-combobox label="Zonas horarias disponibles" :items="timezones" v-model="timezoneSelected"></v-combobox>
                <v-btn :disabled="!timezoneSelected" @click="save">Guardar</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import timezones from '@/common/timezones.js'
export default {
    data(){
        return{
            showDialog: false,
            timezoneSelected: undefined,
            timezones: [],
        }
    },
    created(){
        this.timezones = timezones;
    },
    mounted(){
        this.$parent.$on('openDialogTimezone', () => {
            if( !this.timezoneSelected ) this.timezoneSelected = this.$store.state.passport.timezone || 'America/Santiago';
            this.showDialog = true;
        });
    },
    methods:{
        save(){
            console.log(this.timezoneSelected);
            if(this.timezoneSelected){
                this.$store.dispatch('passport/changeTimezone', this.timezoneSelected).then(() => {
                    this.showDialog = false;
                    window.location.reload();
                });
            }
        }
    }
}
</script>